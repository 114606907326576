<script lang="ts">
  import { createEventDispatcher, onDestroy } from 'svelte';
  import { getApp } from '@firebase/app';
  import { getFirestore, doc, onSnapshot } from '@firebase/firestore';
  import CircularProgress from '@smui/circular-progress/styled';
  import { runTask } from './tasks';
  import Throw from './Throw.svelte';
  import { firebaseAppInitialized } from "../initialize";

  const app = getApp();
  const firestore = getFirestore(app);

  export let path: string;

  let snapshot = null;
  let data = null;
  let error = null;

  let unsub;

  const dispatch = createEventDispatcher();

  async function load(path: string) {
    return new Promise<void>(resolve => {
      if (unsub) {
        unsub();
      }
      unsub = onSnapshot(doc(firestore, path), newSnapshot => {
        error = null;
        snapshot = newSnapshot;
        if (newSnapshot.exists) {
          data = { id: newSnapshot.id, ...newSnapshot.data() };
          console.debug(path, data);
          setTimeout(() => dispatch('data', data), 0);
        }
        resolve();
      }, e => {
        error = e;
        dispatch('error', e);
        resolve();
      });
    });
  }

  onDestroy(() => {
    if (unsub) {
      unsub();
    }
  });

  $: if ($firebaseAppInitialized) {
    runTask(`loadDocument: ${path}`, load(path));
  }
</script>

{#if error !== null}
<slot name="error" {error}>
  <Throw {error}></Throw>
</slot>
{:else if snapshot === null}
<slot name="loading">
  <div class="center">
    <CircularProgress indeterminate></CircularProgress>
  </div>
</slot>
{:else if !snapshot.exists}
<slot name="not-found" {snapshot}>
  Keine Daten verfügbar.
</slot>
{:else}
  <slot {data} {snapshot}></slot>
{/if}
