<script lang="ts">
  import { createEventDispatcher, onDestroy } from 'svelte';
  import { getApp } from '@firebase/app';
  import { getFirestore, onSnapshot, collection, query as queryFn } from '@firebase/firestore';
  import type { CollectionReference, Query } from '@firebase/firestore';
  import CircularProgress from '@smui/circular-progress/styled';
  import { runTask } from './tasks';
  import Throw from './Throw.svelte';
  import { firebaseAppInitialized } from "../initialize";

  const app = getApp();
  const firestore = getFirestore(app);

  export let path: string;
  export let queryConstraints = null;

  let snapshot = null;
  let data = null;
  let error = null;

  let unsub;

  const dispatch = createEventDispatcher();

  async function load(path: string) {
    return new Promise<void>(resolve => {
      if (unsub) {
        console.debug(`loadCollection: ${path}: unsub`);
        unsub();
      }
      let collectionReference: CollectionReference|Query = collection(firestore, path);
      if (Array.isArray(queryConstraints) && queryConstraints.length > 0) {
        collectionReference = queryFn(collectionReference, ...queryConstraints);
      }
      console.debug(`loadCollection: ${path}: queryConstraints=${queryConstraints}`);

      unsub = onSnapshot(collectionReference, newSnapshot => {
        console.debug(`loadCollection: ${path}: onSnapshot`);
        snapshot = newSnapshot;
        data = newSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.debug(path, data);
        setTimeout(() => dispatch('data', data), 0);

        console.debug(`loadCollection: ${path}: resolve`);
        resolve();
      }, e => {
        error = e;
        dispatch('error', e);
        resolve();
      });
    });
  }

  onDestroy(() => {
    if (unsub) {
      unsub();
    }
  });

  $: if ($firebaseAppInitialized) {
    runTask(`loadCollection: ${path}`, load(path));
  }
</script>

{#if error !== null}
<slot name="error" {error}>
  <Throw {error}></Throw>
</slot>
{:else if snapshot === null}
<slot name="loading">
  <div class="center">
    <CircularProgress indeterminate></CircularProgress>
  </div>
</slot>
{:else}
  <slot {data} {snapshot}></slot>
{/if}
