<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, where } from "@firebase/firestore";
  import LayoutGrid, { Cell } from "@smui/layout-grid/styled";
  import { formatPercentage, formatValue, getCompanyDisplayName } from "../util/format";

  let totalValue = 100_000;
  let unsubscribe;
  let top10Companies = [];
  function subscribe() {
    unsubscribe = onSnapshot(
      query(
        collection(getFirestore(), 'co'),
        orderBy('score', 'desc'),
        limit(10)
      ),
      async snapshot => {
        const companiesList = snapshot.docs.map(doc => doc.data());
        addWeights(companiesList);
        await addStockPrices(companiesList);
        top10Companies = companiesList;
      }
    );
  }

  function addWeights(companies) {
    let totalScore = 0;
    for (const company of companies) {
      totalScore += company.score;
    }
    for (const company of companies) {
      company.weight = company.score / totalScore;
    }
  }

  async function addStockPrices(companies) {
    const isins = companies.flatMap(x => x.isins);
    const snapshots = await Promise.all(isins.map(isin => getDoc(doc(getFirestore(), `stocks/${isin}`))));
    const stocks = snapshots.map(doc => doc.data());
    for (const company of companies) {
      const companyStocks = stocks
        .filter(x => company.isins.includes(x.isin))
        .sort((a, d) => {
          if (a.stockPrices && !d.stockPrices) {
            return 1;
          }
          if (d.stockPrices && !a.stockPrices) {
            return -1;
          }
          if (!a.stockPrices && !d.stockPrices) {
            return 0;
          }
          return a.stockPrices.at(-1) - d.stockPrices.at(-1)
        });
      companyStocks[0].stockPrices?.at(-1);
    }
  }

  let target;
  function copyTable() {
    if (!target) {
      return;
    }

    const range = document.createRange();
    range.setStart(target, 0);
    range.setEndAfter(target);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand("copy");
    window.getSelection()?.removeAllRanges();
  }

  onMount(() => {
    subscribe();
  });
  onDestroy(() => {
    unsubscribe?.();
  });
</script>


<LayoutGrid>
  <Cell span={12}>
    <table bind:this={target}>
      <thead>
        <tr>
          <th>Name</th>
          <th>ISIN(s)</th>
          <th>Anteil</th>
          <!-- <th>Wert</th> -->
        </tr>
      </thead>
      <tbody>
        {#each top10Companies as company}
        <tr>
          <td>{getCompanyDisplayName(company)}</td>
          <td>{company.isins}</td>
          <td>{formatPercentage(company.weight)}</td>
          <!-- <td>{formatValue(totalValue * company.weight, 'EUR')}</td> -->
        </tr>
        {/each}
      </tbody>
    </table>
    <div>
      <button on:click={copyTable}>kopieren</button>
    </div>
  </Cell>
</LayoutGrid>
