<script lang="ts">
  import Checkbox from '@smui/checkbox/styled';
  import NewsletterTable from "./NewsletterTable.svelte";
  import Collection from '../../util/Collection.svelte';

  let includeTest = false;

  function filter(newsletter, includeTest: boolean) {
    if (includeTest) {
      return newsletter;
    } else {
      return newsletter.filter(ns => !ns.email.endsWith('@insiderpie.de'));
    }
  }
</script>

<Collection path="newsletter" let:data={newsletter}>
  <!-- The control for this label will be rendered by the Checkbox -->
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label style="margin: 1em; display: flex; align-items: center">
    <Checkbox bind:checked={includeTest}></Checkbox>
    <span>mit @insiderpie.de</span>
  </label>
  <NewsletterTable newsletter={filter(newsletter, includeTest)}></NewsletterTable>
</Collection>
