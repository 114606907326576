<script>
  import {
    addDoc,
    collection,
    doc,
    getDocs,
    getFirestore,
    query,
    updateDoc,
    where,
  } from "firebase/firestore";
  import { router } from 'yrv';
  import Textfield from '@smui/textfield/styled';
  import Button from '@smui/button/styled';
  import LinearProgress from '@smui/linear-progress/styled';
  import { buildMessage, htmlHeadText, htmlLegalText } from './common';
  import Document from '../../util/Document.svelte';

  export let user;
  export let newsletterId;

  let htmlCode = '';
  let htmlFooter = '';
  let subject = '';
  let isUpdating = false;
  let isDirty = false;
  let wasSentToSelf = false;

  function getRef() {
    return doc(getFirestore(), `newsletter-drafts/${newsletterId}`)
  }

  async function handleUpdate() {
    isUpdating = true;
    await updateDoc(getRef(), {htmlCode, subject, htmlFooter});
    isDirty = false;
    isUpdating = false;
  }

  function handleData(ev) {
    if (ev.detail) {
      const data = ev.detail;
      if (data.htmlCode) {
        htmlCode = data.htmlCode;
      }
      if (data.subject) {
        subject = data.subject;
      }
      if (data.htmlFooter) {
        subject = data.htmlFooter;
      }
    }
  }

  async function sendToSelf(ref) {
    await handleUpdate(ref);
    if (user) {
      const message = buildMessage({
        email: user.email,
        name: user.displayName,
        unsubToken: "UNSUB_TOKEN"
      }, {
        htmlCode,
        htmlFooter,
        subject
      });
      await addDoc(
        collection(getFirestore(), 'mail'),
        message
      );
      wasSentToSelf = true;
    }
  }

  function isE2ETestEmail(sub) {
    const { email } = sub;
    return email.startsWith('insiderpie-newsletter-test') && email.endsWith('@insiderpie.de');
  }

  async function sendToAll(ref) {
    await handleUpdate(ref);
    const subscribersSnapshot = await getDocs(
      query(
        collection(getFirestore(), 'newsletter'),
        where('confirmed', '==', true)
      )
    );
    const subscribers = subscribersSnapshot.docs.map(doc => doc.data());
    const sendTo = subscribers.filter(sub => !isE2ETestEmail(sub));

    if (confirm(`Newsletter an ${sendTo.length} Empfänger senden?`)) {
      const sendPromises = [];
      for (const { unsubToken, name, email } of sendTo) {
        const message = buildMessage({
          email,
          name,
          unsubToken
        }, {
          htmlCode,
          htmlFooter,
          subject
        });
        sendPromises.push(
          addDoc(
            collection(getFirestore(), 'mail'),
            message
          )
        );
      }
     await Promise.all(sendPromises);
    }
  }

  $: if (isDirty) {
    wasSentToSelf = false;
  }
</script>

{#if isUpdating}
<LinearProgress indeterminate />
{/if}
{#if newsletterId}
  <Document path={`newsletter-drafts/${newsletterId}`} let:data={draft} let:snapshot on:data={handleData} once>
    <div class="m">
      <h2>{draft.title}</h2>
      <Button style="margin-right: 1em; margin-bottom: 1em" on:click={async () => await handleUpdate(snapshot.ref)} variant="outlined" disabled={isUpdating || !isDirty}>Speichern</Button>
      <Button style="margin-right: 1em; margin-bottom: 1em" on:click={async () => await sendToSelf(snapshot.ref)} variant="raised" disabled={isUpdating || wasSentToSelf}>An mich senden</Button>
      <Button style="margin-right: 1em; margin-bottom: 1em" variant="raised" on:click={async () => await sendToAll(snapshot.ref)} disabled={isUpdating || !wasSentToSelf}>Veröffentlichen</Button>
      <Button style="margin-bottom: 1em" variant="raised" href={`/preview-newsletter/${$router.params.id}`} target="_blank" on:click={async () => await handleUpdate(ref)}>
        Vorschau öffnen
      </Button>
    </div>
    <div class="m">
      <Textfield style="width: 100%" bind:value={subject} bind:dirty={isDirty} label="Betreff" variant="outlined" disabled={isUpdating}>
      </Textfield>
    </div>
    <div class="m">
      <Textfield style="width: 100%; height: 4em" value={htmlHeadText} bind:dirty={isDirty} label="Einheitlicher Header" textarea disabled>
      </Textfield>
    </div>
    <div class="m">
      <Textfield style="width: 100%; height: 20em" bind:value={htmlCode} bind:dirty={isDirty} label="HTML Text" textarea disabled={isUpdating}>
      </Textfield>
    </div>
    <div class="m">
      <Textfield style="width: 100%; height: 4em" value={htmlLegalText} bind:dirty={isDirty} label="Gesetzliche Angaben" textarea disabled>
      </Textfield>
    </div>
    <div class="m">
      <Textfield style="width: 100%; height: 20em" bind:value={htmlFooter} bind:dirty={isDirty} label="HTML Text" textarea disabled={isUpdating}>
      </Textfield>
    </div>
    <div slot="loading">Loading...</div>
  </Document>
{/if}

<style lang="scss">
  .topbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .mbr {
    margin-top: 0.4em;
    margin-right: 1em;
    margin-bottom: 1em;
  }

  .m {
    margin: 1em;
  }
</style>
