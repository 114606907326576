<script>
  import LinearProgress from '@smui/linear-progress/styled';
  import InvestorTable from "./InvestorTable.svelte";
  import Collection from '../../util/Collection.svelte';

  let isUpdating = false;
</script>

{#if isUpdating}
<LinearProgress indeterminate />
{/if}
<Collection path={'investors'} let:data={investors} let:ref>
  <InvestorTable investors={investors}></InvestorTable>
  <div slot="loading">Loading...</div>
</Collection>

<style lang="scss">
  .m {
    margin: 1em;
  }
</style>
