<script>
  import { router } from "yrv";
  import { htmlHeadText, htmlLegalText } from "./common";
  import Document from '../../util/Document.svelte';

  export let user;
  export let newsletterId;
</script>

{#if newsletterId}
  <Document path={`newsletter-drafts/${newsletterId}`} let:data={draft} let:ref>
    <div class="m">
      <div>
        {@html htmlHeadText}
        {@html draft.htmlCode}
        {@html htmlLegalText}
        {@html draft.htmlFooter}
      </div>
    </div>
    <div slot="loading">Loading...</div>
  </Document>
{/if}

<style lang="scss">
  .m {
    margin: 1em;
  }
</style>
