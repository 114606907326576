<script lang="ts">
  import Textfield from "@smui/textfield/styled";
  import HelperText from "@smui/textfield/helper-text/styled";
  import Icon from '@smui/textfield/icon/styled';
  import Button, { Label } from "@smui/button/styled";
  import Paper, { Title, Content } from "@smui/paper/styled";
  import {
    GoogleAuthProvider,
    signInWithRedirect,
    signInWithEmailAndPassword,
    getAuth,
    type User,
  } from "@firebase/auth";
  import { runTask } from "../util/tasks";
  import { navigateTo } from "yrv";

  export let user: User;

  let email = '';
  let password = '';

  let error: string = null;
  let errorEmail = false;
  let errorPassword = false;

  async function submitGoogle() {
    const provider = new GoogleAuthProvider();
    await runTask('signInWithRedirect', signInWithRedirect(getAuth(), provider));
  }

  async function submitEmailAndPassword(email: string, password: string) {
    try {
      await runTask('linkWithCredential', signInWithEmailAndPassword(getAuth(), email, password));
      navigateTo('/');
    } catch (e) {
      handleErrorCode(e.code);
    }
  }

  function handleErrorCode(errorCode: string): void {
    error = errorCode;

    switch (errorCode) {
      case 'auth/weak-password':
        errorPassword = true;
        break;
      case 'auth/invalid-email':
        errorEmail = true;
        break;
    }
  }

  function resetError() {
    errorEmail = false;
    errorPassword = false;
    error = null;
  }

  $: if (user && !user.isAnonymous) {
    console.debug('login: already logged in and not anonymous');
    navigateTo('/');
  }
</script>

<Paper elevation={4}>
  <Title>
    <h1 class="h4">Anmelden</h1>
  </Title>
  <Content>
    <Textfield
      bind:value={email}
      invalid={errorEmail}
      on:input={resetError}
      label="E-Mail"
      style="width: 100%;" helperLine$style="width: 100%;" input$type="email"
    >
      <Icon class="material-icons" slot="leadingIcon">email</Icon>
      <HelperText slot="helper" persistent>
        {#if error === 'auth/invalid-email'}
          Diese E-Mail Adresse ist ungültig
        {:else if error === 'auth/user-not-found'}
          Es gibt keinen Account mit dieser E-Mail Adresse
        {/if}
      </HelperText>
    </Textfield>
    <Textfield
      bind:value={password} label="Passwort"
      invalid={errorPassword}
      on:input={resetError}
      style="width: 100%;" helperLine$style="width: 100%;"
      input$type="password"
    >
      <Icon class="material-icons" slot="leadingIcon">password</Icon>
      <HelperText slot="helper" persistent>
        {#if error === 'auth/wrong-password'}
          Die E-Mail Adresse und das Passwort stimmen nicht überein
        {/if}
      </HelperText>
    </Textfield>
    <Button
      variant="raised"
      style="width: 100%"
      on:click={() => submitEmailAndPassword(email, password)}
    >
      <Label>Mit E-Mail anmelden</Label>
    </Button>

    <div class="center my">
      oder
    </div>

    <Button
      variant="outlined"
      style="width: 100%; background-color: #db4437; color: white"
      on:click={() => submitGoogle()}
    >
      <Label>Mit Google anmelden</Label>
    </Button>
  </Content>
</Paper>
