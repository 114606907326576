<script lang="ts">
  import Card from "@smui/card/styled";
  import IconButton from "@smui/icon-button/styled";
  import { getCompanyDisplayName, getCompanyLogo } from "../../util/format";
  import { createEventDispatcher } from "svelte";

  export let company;
  let logo;

  const dispatch = createEventDispatcher();

  function onCompanyDeleted() {
    dispatch('deleted');
  }

  $: if (company) {
    logo = getCompanyLogo(company);
  }
</script>

<Card>
  <div class="company-info">
    <div class="logo-container">
      {#if logo}
        <img src={logo} alt="" style="max-width: 32px; max-height: 32px; margin-right: 1em" />
      {/if}
    </div>

    <div class="company-name-container">
      <h2 class="h5 company-name">
        {getCompanyDisplayName(company)}
      </h2>
      <span class="secondary">
        {#if company.tickers?.[0]}
          {company.tickers[0]}
        {/if}
      </span>
    </div>

    <div class="flex-space"></div>

    <IconButton class="material-icons" aria-label="Delete" on:click={onCompanyDeleted}>delete</IconButton>
  </div>
</Card>

<style lang="scss">
  .company-info {
    margin: 1em;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
  }

  .flex-space {
    flex-grow: 1;
  }

  .company-name-container {
    display: flex;
    flex-direction: column;
    .company-name {
      font-size: 1em;
      margin: 0;
    }
    .secondary {
      font-size: small;
    }
  }
</style>
