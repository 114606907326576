import type { ICompany2, IStock } from "functions/src/types";

const percentageFormatter = new Intl.NumberFormat('de-DE', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export function formatPercentage(percent: number): string {
  return percentageFormatter.format(percent);
}

const amountFormatter = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 0,
});
export function formatAmount(amount: number): string {
  return amountFormatter.format(amount);
}

const realNumberFormatter = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export function formatRealNumber(amount: number): string {
  return realNumberFormatter.format(amount);
}

function formatNumberWithCurrency(value: number, currency: string): string {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export function formatValue(value: number, currency = 'USD') {
  return new Intl.NumberFormat('de-DE', {
    notation: 'compact',
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export function formatValueEuro(value: number): string {
  return formatValue(value, 'EUR');
}

export function convertToDate(date: any): Date {
  if (typeof date === 'string') {
    return new Date(date);
  } else if (typeof date.toDate === 'function') {
    return date.toDate();
  }
  return date;
}

function isInvalid(date: Date): boolean {
  return isNaN(date.getTime());
}

export function formatDate(date: any): string {
  date = convertToDate(date);
  if (isInvalid(date)) {
    return '';
  }
  return new Intl.DateTimeFormat('de-DE', {
    dateStyle: 'medium',
  }).format(date);
}

export function formatDateLong(date: any): string {
  date = convertToDate(date);
  if (isInvalid(date)) {
    return '';
  }
  return new Intl.DateTimeFormat('de-DE', {
    dateStyle: 'full',
  }).format(date);
}

export function formatTimestamp(date: any): string {
  date = convertToDate(date);
  if (isInvalid(date)) {
    return '';
  }
  return new Intl.DateTimeFormat('de-DE', {
    dateStyle: 'full',
    timeStyle: 'short'
  }).format(date);
}

export function formatName(name: string): string {
  return name;
}

export function getCompanyDisplayName(company: ICompany2): string {
  let displayName = company?.article
    ?? company?.articleEn
    ?? company?.nameFromWikipedia
    ?? company?.name
    ?? company?.nameFromGLEIF
    ?? company?.lei;
  const UNTERNEHMEN = ' (Unternehmen)';
  if (displayName) {
    if (displayName.endsWith(UNTERNEHMEN)) {
      return displayName.substr(0, displayName.length - UNTERNEHMEN.length);
    }
    return displayName;
  }
  return '';
}

export function getStockDisplayName(stock: IStock): string {
  const name = stock?.nameFromDpaAFX ?? stock?.nameFromBafin;
  const isin = stock?.isin;
  const ticker = stock?.ticker;
  if (name) {
    return `${name} (${ticker} | ${isin})`;
  } else if (isin && ticker) {
    return `${ticker} | ${isin}`;
  }
  return '';
}

export function getStockDisplayNameShort(stock: IStock): string {
  const isin = stock?.isin;
  const ticker = stock?.ticker;
  if (ticker) {
    return ticker;
  } else if (isin) {
    return isin;
  }
  return '';
}

export function getCompanyDescription(company: ICompany2) {
  return company?.description ?? company?.descriptionFromWikipedia;
}

export function getCompanyLogo(company: ICompany2) {
  return company?.logo ?? company?.logoFrom8MarketCap ?? company?.logoFrom8MarketCapCrawlerPNG ?? company?.logoFromWikidata;
}

export function formatScore(company: ICompany2) {
  return Math.round(company.score ?? 50);
}
