export const environment = {
  FIREBASE_CONFIG: {
    "apiKey": "AIzaSyCK9DIW4Sy3Ma0IQd4n4ew_1W3vZ9O7hbI",
    "authDomain": "insiderpie-2d4aa.firebaseapp.com",
    "projectId": "insiderpie-2d4aa",
    "storageBucket": "insiderpie-2d4aa.appspot.com",
    "messagingSenderId": "752448597311",
    "appId": "1:752448597311:web:9f5c5ac76e335bf030f78a",
    "measurementId": "G-THQRZWYGH4"
  },
  FIREBASE_APPCHECK_DEBUG_TOKEN: "614bc387-8792-452b-a1d0-e038f47af544",
  FIREBASE_TARGET: "production",
  NODE_ENV: "production",
  MEILI_HOST: "search.insiderpie.de",
};
