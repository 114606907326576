import { get, writable } from "svelte/store";
import type { Readable } from "svelte/store";
import { MeiliSearch } from "meilisearch";
import { onSnapshot, doc, getFirestore } from "@firebase/firestore";
import { environment } from "./env/environment";
import { afterInitialize } from "./initialize";

const meiliKeysWritable = writable();
const meiliKeys = meiliKeysWritable as Readable<{key: string}>;

function listenToMeiliApiKey(): void {
  onSnapshot(
    doc(getFirestore(), 'meili/keys'),
    snapshot => {
      const data = snapshot.data();
      meiliKeysWritable.set(data);
      client = null;
      console.debug('search: meili/keys:', data);
    }
  );
}

async function getMeiliApiKey(): Promise<string> {
  const cached = get(meiliKeys);
  if (cached) {
    return cached.key;
  }
  return new Promise<string>(resolve => {
    const unsub = meiliKeys.subscribe(value => {
      if (value) {
        unsub();
        resolve(value.key);
      }
    });
  });
}

let client: MeiliSearch|null = null;

async function getMeili() {
  if (!client) {
    client = new MeiliSearch({
      host: "https://" + environment.MEILI_HOST,
      apiKey: await getMeiliApiKey(),
    });
  }
  return client;
}

export async function search(query: string) {
  const client = await getMeili();
  return client.index("i8e_v2").search(query, {
    highlightPreTag: '<b>',
    highlightPostTag: '</b>'
  });
}

afterInitialize(listenToMeiliApiKey);
