import { writable } from 'svelte/store';

export const NetworkQuality = {
  /**
   * User is offline. Non-cached media will not be available.
   * Show an appropriate explanation to the user instead.
   */
  offline: 0,
  /**
   * Low data usage recommended. Use low-res images, don't autoplay video,
   * reduce update frequency, etc.
   */
  low: 1,
  /**
   * Average data usage recommended. This is the default value.
   */
  medium: 2,
  /**
   * High data usage possible. Use high-res images, autoplay if ok for
   * user experience, use frequent updates, etc.
   */
  high: 3
};

const networkQuality = writable(NetworkQuality.medium);

let pingMeasureInterval = null;
let pingMeasureCountdown = 3;
let measuredReqTime = null;

async function measurePingTime() {
  const headers = new Headers();
  headers.append('cache-control', 'no-cache');
  headers.append('pragma', 'no-cache');
  const start = performance.now();
  await fetch('/', { method: 'HEAD', headers });
  measuredReqTime = performance.now() - start;
  console.debug(`network-service: measured request time of ${measuredReqTime}`);
  update();

  pingMeasureCountdown--;
  if (pingMeasureCountdown === 0) {
    clearInterval(pingMeasureInterval);
  }
}

function getConnection() {
  return navigator.connection ?? navigator.mozConnection ?? navigator.webkitConnection;
}

function getNetworkQuality() {
  // First check if we are offline
  if (!navigator.onLine) {
    console.debug('network-service: device is offline');
    return NetworkQuality.offline;
  }

  // Use the NetworkInformation api, if available
  const connection = getConnection();
  if (connection !== undefined) {

    // If user has enabled reduced data use in their browser, set quality to low
    if (connection.saveData) {
      console.debug('network-service: user has enabled reduced data usage');
      return NetworkQuality.low;
    }

    // Otherwise, set quality based on estimated network speed
    switch (connection.effectiveType) {
      case 'slow-2g':
      case '2g':
        console.debug('network-service: slow connection');
        return NetworkQuality.low;
      case '3g':
        console.debug('network-service: medium connection');
        return NetworkQuality.medium;
      case '4g':
        console.debug('network-service: fast connection');
        return NetworkQuality.high;
    }
  } else {
    // If the NetworkInformation api is unavailable, manually measure ping time
    if (pingMeasureInterval === null) {
      console.debug('network-service: navigator.connection not available, beginning network quality probes');
      pingMeasureInterval = setInterval(measurePingTime, 10000);
    }
    if (measuredReqTime !== null) {
      if (measuredReqTime === -1) {
        return NetworkQuality.offline;
      } else if (measuredReqTime < 4) {
        return NetworkQuality.high;
      } else if (measuredReqTime < 6) {
        return NetworkQuality.medium;
      }
      return NetworkQuality.low;
    }
  }

  // Return medium as ultimate fallback
  console.debug('network-service: navigator.connection not available, falling back to medium quality');
  return NetworkQuality.medium;
}

function update() {
  networkQuality.set(getNetworkQuality());
}

window.addEventListener('online', update);
window.addEventListener('offline', update);
window.addEventListener('load', update);

export default networkQuality;
