<script>
  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Label,
  } from "@smui/data-table/styled";
  import IconButton from '@smui/icon-button/styled';
  import { sortArrayBy } from "../../util/tables";

  export let newsletter;

  let subscribers = [];
  let sort = "addedAt";
  let sortDirection = "descending";

  function handleSort() {
    subscribers = sortArrayBy(subscribers, sort, sortDirection);
  }

  $: {
    subscribers = newsletter;
    handleSort();
  }

  function formatDate(value) {
    if (typeof value === 'object' && 'seconds' in value) {
      return new Date(value.seconds * 1000).toLocaleString();
    }
    return '';
  }

  function formatBoolean(value) {
    return !!value ? 'Ja' : 'Nein';
  }
</script>

<div style="margin: 1em;">
  Die Daten sind nur für die Nutzung im Rahmen des InsiderPie-Newsletters vorgesehen.
  Insgesamt {subscribers.length} Anmeldungen
</div>
<DataTable
  sortable
  bind:sort
  bind:sortDirection
  on:MDCDataTable:sorted={handleSort}
  style="width: 100%; overflow: auto"
  stickyHeader={true}
>
  <Head>
    <Row>
      <Cell columnId={"addedAt"}>
        <Label>Hinzugefügt am</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"confirmed"}>
        <Label>Bestätigt?</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"confirmedAt"}>
        <Label>Bestätigt am</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"email"}>
        <Label>E-Mail</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"name"}>
        <Label>Name</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#each subscribers as subscriber}
      <Row>
        <Cell>{formatDate(subscriber.addedAt)}</Cell>
        <Cell>{formatBoolean(subscriber.confirmed)}</Cell>
        <Cell>{formatDate(subscriber.confirmedAt)}</Cell>
        <Cell>{subscriber.email}</Cell>
        <Cell>{subscriber.name}</Cell>
      </Row>
    {/each}
  </Body>
</DataTable>
