<script lang="ts">
  import LayoutGrid, { Cell } from "@smui/layout-grid/styled";
  import Textfield from "@smui/textfield/styled";
  import Button from "@smui/button/styled";
  import SearchBar from "../../SearchBar.svelte";
  import CompanyCard from "./CompanyCard.svelte";
  import { doc, getFirestore, setDoc } from "@firebase/firestore";

  const leis = new Set();
  let companies = [];
  let json = '';

  function selectCompany(event) {
    const company = event.detail;
    if (!company?.lei) {
      return;
    }

    const { lei } = company;
    if (leis.has(lei)) {
      return;
    }

    leis.add(lei);
    companies.push(company);
    companies = companies;
  }

  function removeCompany(company) {
    const { lei } = company;
    leis.delete(lei);
    companies = companies.filter(x => x.lei !== lei);
  }

  function isJSONObject(string) {
    try {
      const value = JSON.parse(string);
      return typeof value === 'object';
    } catch {
      return false;
    }
  }

  async function apply() {
    const jsonValue = JSON.parse(json);
    const confirmed = confirm(`apply change to ${companies.length} companies?`);
    if (!confirmed) {
      return;
    }

    await Promise.all(companies.map(company => {
      return setDoc(
        doc(getFirestore(), `co/${company.lei}`),
        jsonValue,
        { merge: true }
      );
    }));

    alert(`change applied to ${companies.length} companies!`);
  }

  $: jsonInvalid = !isJSONObject(json);
  $: applyDisabled = jsonInvalid || companies.length === 0;
</script>

<LayoutGrid>
  <Cell span={12}>
    <h1 class="h4">
      Manual lists
    </h1>
    <p>
      Create a list with any number of companies,
      then merge arbitrary JSON into those companies.
    </p>
    <Button variant="raised" disabled={applyDisabled} on:click={apply}>
      Apply
    </Button>
  </Cell>

  <Cell span={12}>
    <div class="center">
      <Textfield
        textarea
        bind:value={json}
        invalid={jsonInvalid}
        style="min-width: 85%"
        label="Enter any JSON object"
      />
    </div>
  </Cell>

  <Cell span={12}>
    <SearchBar on:select={selectCompany} />
  </Cell>


  <Cell span={12}>
    {#each companies as company}
      <CompanyCard {company} on:deleted={() => removeCompany(company)} />
    {/each}
  </Cell>
</LayoutGrid>
