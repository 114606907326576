<script lang="ts">
  import LayoutGrid, { Cell } from "@smui/layout-grid/styled";
  import Button, { Label } from "@smui/button/styled";
  import Textfield from "@smui/textfield/styled";
  import HelperText from "@smui/textfield/helper-text/styled";
  import { getFunctions, httpsCallable } from "@firebase/functions";
  import { getApp } from "@firebase/app";

  let input: string = '';
  let isWorking = false;
  let addedOK: string[] = [];

  function parseRequest(input: string) {
    const parts = input.split(' ');
    let isin;
    let lei;
    for (const part of parts) {
      if (part.length === 12) {
        isin = part;
      } else if (part.length === 20) {
        lei = part;
      }
    }
    if (isin && lei) {
      return { isin, lei };
    }
    return null;
  }

  async function executeAdd() {
    const entries = input.split('\n');

    if (entries.length > 0) {
      isWorking = true;
      const requests = entries.map(parseRequest).filter(x => x !== null);

      const functions = getFunctions(getApp(), 'europe-west3');
      const callable = httpsCallable(functions, 'addCompaniesByISINsOnCall');
      const result = await callable({ requests });

      if (result.data?.ok) {
        addedOK = result.data.ok;
      }
      isWorking = false;
    }
  }
</script>

<LayoutGrid>
  <Cell span={12}>
    <h1 class="h4">
      Add companies by ISIN + LEI
    </h1>
  </Cell>

  <Cell span={8}>
    <Textfield
      textarea
      style="width: 100%; min-height: 8em"
      bind:value={input}
      variant="outlined"
      disabled={isWorking}
      label="Requests"
    >
      <HelperText slot="helper" persistent>
        One request per line. Each request must include one ISIN and one LEI, separated by whitespace.
      </HelperText>
    </Textfield>
    <Button on:click={executeAdd} disabled={!input || isWorking} variant="raised">
      <Label>
        Add
      </Label>
    </Button>
  </Cell>

  <Cell span={4}>
    <p>
      Added OK (open in new tab):
    </p>
    <ul>
      {#if addedOK}
        {#each addedOK as lei}
          <li>
            <a href="https://app.insiderpie.de/companies/{lei}" target="_blank">
              {lei}
            </a>
          </li>
        {/each}
      {/if}
    </ul>
  </Cell>
</LayoutGrid>
