export const htmlHeadText = `<div style="font-size: 1.5em">
<a style="display: flex; align-items: center; text-decoration: none; color: black" href="https://insiderpie.de/?utm_source=newsletter">
  <img src="https://firebasestorage.googleapis.com/v0/b/insiderpie-2d4aa.appspot.com/o/public%2F1024-1024.png?alt=media&token=4135344e-3c89-4e59-8372-514bdbae68e9" alt="InsiderPie Logo" width="40" height="40" style="margin-right: 1em">
  <h3>Investieren wie die Besten</h3>
</a>
<hr>`;

export const htmlLegalText = `<p>Viele Grüße,</p>
<p>Dein InsiderPie-Team</p>
<div style="display: flex; margin-bottom: 0.2em">
  <div style="margin: 0.2em">
    <a href="https://www.facebook.com/InsiderPie" aria-label="Facebook: https://www.facebook.com/InsiderPie"> 
      <img height="24" width="24" alt="Facebook" src="https://insiderpie.de/wp-content/uploads/2022/07/facebook.png">
    </a>
  </div>
  <div style="margin: 0.2em">
    <a href="https://twitter.com/insider_pie" aria-label="Twitter: https://twitter.com/insider_pie"> 
      <img height="24" width="24" alt="Twitter" src="https://insiderpie.de/wp-content/uploads/2022/07/twitter.png">
    </a>
  </div>
  <div style="margin: 0.2em">
    <a href="https://www.instagram.com/insiderpie/" aria-label="Instagram: https://www.instagram.com/insiderpie/"> 
      <img height="24" width="24" alt="Instagram" src="https://insiderpie.de/wp-content/uploads/2022/07/instagram.png">
    </a>
  </div>
  <div style="margin: 0.2em">
    <a href="https://www.linkedin.com/company/insiderpie" aria-label="LinkedIn: https://www.linkedin.com/company/insiderpie"> 
      <img height="24" width="24" alt="LinkedIn" src="https://insiderpie.de/wp-content/uploads/2022/07/linkedin.png">
    </a>
  </div>
</div>
</div>
<hr>
<p>
  <a href="https://app.insiderpie.de/newsletter?email={EMAIL}">
    Wenn du den Namen ändern möchtest, mit dem wir dich in unserem Newsletter ansprechen, klicke hier.
  </a>
</p>
<hr>
<p><a href="https://insiderpie.de/impressum">Impressum</a> | <a href="https://app.insiderpie.de/unsub-newsletter?unsubToken={UNSUB_TOKEN}">Abbestellen</a></p>`;


export function buildMessage({ email, name, unsubToken }, { subject, htmlCode, htmlFooter }) {
  /**
   * @param input {string}
   * @return {string}
   */
  function replaceTokens(input) {
    return input.replaceAll('{NAME}', name)
      .replaceAll('{EMAIL}', email)
      .replaceAll('{UNSUB_TOKEN}', unsubToken);
  }

  const html = `<html lang="de">
  <body>
    ${replaceTokens(htmlHeadText)}
    ${replaceTokens(htmlCode)}
    ${replaceTokens(htmlLegalText)}
    ${replaceTokens(htmlFooter)}
  </body>
</html>`;

  return {
    to: [email],
    from: 'InsiderPie <info@insiderpie.de>',
    message: {
      subject: subject.replace('NAME', name),
      html,
    },
    headers: {
      'list-unsubscribe': `<mailto:unsubscribe@insiderpie.de?subject=${unsubToken}>`,
    },
  };
}
