<script>
  import { Link } from 'yrv';
  import Paper, { Title, Subtitle, Content } from "@smui/paper/styled";
</script>

<section style="margin: 1em">
  <h1>
    InsiderPie interne Homepage. Willkommen :)
  </h1>

  <Paper elevation="4">
    <h2>Newsletter</h2>
    <p>
      <Link href="/newsletter">Newsletter Anmeldungen ansehen</Link>
    </p>
    <p>
      <Link href="/draft-newsletter">Newsletter Entwerfen</Link>
    </p>
  </Paper>
  <Paper elevation="4">
    <h2>Manual Tasks</h2>
    <p>
      <Link href="/add-companies-by-isin">Add companies by ISIN</Link>
    </p>
    <p>
      <Link href="/manual-lists">Manual lists</Link>
    </p>
    <p>
      <Link href="/backtests">Backtests</Link>
    </p>
    <p>
      <Link href="/debug-notifications">Debug-Notifications</Link>
    </p>
  </Paper>
</section>

<style lang="scss">
  :global(.smui-paper) {
    margin-bottom: 1em;
    h2 {

      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
</style>
