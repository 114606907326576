<script>
  import { navigateTo, router, Link } from "yrv";
  import IconButton from "@smui/icon-button/styled";
  import Textfield from "@smui/textfield/styled";
  import LinearProgress from '@smui/linear-progress/styled';
  import Select, { Option } from '@smui/select/styled';
  import InvestorImage from './Image.svelte';
  import Document from '../../util/Document.svelte';
  import Collection from '../../util/Collection.svelte';

  let name = "";
  let nameEnabled = false;
  let investmentStyleName = "";
  let investmentStyleNameEnabled = false;
  let annualreturn5y = 0;
  let annualreturn5yEnabled = false;
  let birthDate = null;
  let birthDateEnabled = false;
  let birthYear = 0;
  let birthYearEnabled = false;
  let profileImageFiles = null;
  let profileImageFilesEnabled = false;
  let isUpdating = false;

  function handleData(ev) {
    if (ev.detail && ev.detail.data) {
      const data = ev.detail.data;
      if (data.name) {
        name = data.name;
      }
      if (data.investmentStyleName) {
        investmentStyleName = data.investmentStyleName;
      }
      if (data.annualreturn5y) {
        annualreturn5y = data.annualreturn5y.toString(10);
      }
      if (data.birthYear) {
        birthYear = data.birthYear.toString(10);
      }
    }
  }

  async function update(ref, data) {
    isUpdating = true;
    await ref.update(data);
    isUpdating = false;
  }

  async function updateName(ref) {
    if (nameEnabled) {
      await update(ref, { name });
      nameEnabled = false;
    }
  }

  async function updateInvestmentStyleName(ref) {
    if (investmentStyleNameEnabled) {
      await update(ref, { investmentStyleName });
      investmentStyleNameEnabled = false;
    }
  }

  async function updateAnnualreturn5y(ref) {
    if (annualreturn5yEnabled) {
      await update(ref, { annualreturn5y: parseFloat(annualreturn5y) });
      annualreturn5yEnabled = false;
    }
  }

  async function updateBirthYear(ref) {
    if (birthYearEnabled) {
      await update(ref, { birthYear: parseInt(birthYear) });
      birthYearEnabled = false;
    }
  }

  async function updateBirthDate(ref) {
    if (birthDateEnabled) {
      try {
        const date = new Date(birthDate);
        if (date.getTime() !== 0) {
          await update(ref, { birthDate: date });        
        }
      } catch {
        /* Date failed to parse, do nothing */
      }
      birthDateEnabled = false;
    }
  }

  $: if (profileImageFiles !== null && profileImageFiles.length) {
    console.debug(profileImageFiles);
  }
</script>

{#if isUpdating}
<LinearProgress indeterminate />
{/if}
<Document path={`investors/${$router.params.id}`} let:data={investor} let:ref on:data={handleData}>
  <div class="m">
    <div class="group">
      <span class="mr">Name:</span>
      <Textfield bind:value={name} variant="outlined" disabled={!nameEnabled} />
      {#if nameEnabled}
        <IconButton on:click={async () => await updateName(ref)} class="material-icons">save</IconButton>
      {:else}
        <IconButton on:click={() => (nameEnabled = true)} class="material-icons">edit</IconButton>
      {/if}
    </div>
    <div class="group">
      <span class="mr">Investmentstil:</span>
      <Collection path={'investment_styles'} let:data={investmentStyles} let:ref={investmentStylesRef}>
        <Select bind:value={investmentStyleName} variant="outlined" disabled={!investmentStyleNameEnabled}>
          {#each investmentStyles as investmentStyle}
            <Option value={investmentStyle.id}>{investmentStyle.name}</Option>
          {/each}
          <Option on:click={() => navigateTo('/investment-styles')}><Link href="/investment-styles">Neu hinzufügen</Link></Option>
        </Select>
      </Collection>
      {#if investmentStyleNameEnabled}
        <IconButton on:click={async () => await updateInvestmentStyleName(ref)} class="material-icons">save</IconButton>
      {:else}
        <IconButton on:click={() => (investmentStyleNameEnabled = true)} class="material-icons">edit</IconButton>
      {/if}
    </div>
    <div class="group">
      <span class="mr">Return 5y:</span>
      <Textfield bind:value={annualreturn5y} variant="outlined" disabled={!annualreturn5yEnabled} />
      {#if annualreturn5yEnabled}
        <IconButton on:click={async () => await updateAnnualreturn5y(ref)} class="material-icons">save</IconButton>
      {:else}
        <IconButton on:click={() => (annualreturn5yEnabled = true)} class="material-icons">edit</IconButton>
      {/if}
    </div>
    <div class="group">
      <span class="mr">Geburtsdatum:</span>
      <Textfield bind:value={birthDate} variant="outlined" disabled={!birthDateEnabled} type="date" />
      {#if birthDateEnabled}
        <IconButton on:click={async () => await updateBirthDate(ref)} class="material-icons">save</IconButton>
      {:else}
        <IconButton on:click={() => (birthDateEnabled = true)} class="material-icons">edit</IconButton>
      {/if}
    </div>
    <div class="group">
      <span class="mr" class:linethrough={birthDate}>Geburtsjahr:</span>
      <Textfield bind:value={birthYear} variant="outlined" disabled={!birthYearEnabled} />
      {#if birthYearEnabled}
        <IconButton on:click={async () => await updateBirthYear(ref)} class="material-icons">save</IconButton>
      {:else}
        <IconButton on:click={() => (birthYearEnabled = true)} class="material-icons">edit</IconButton>
      {/if}
    </div>
    <div class="group">
      <span class="mr">Profilbild</span>
      <div style="width: 4em" class="mr">
        <InvestorImage {investor} investorId={$router.params.id} chip={true}></InvestorImage>
      </div>
      {#if profileImageFilesEnabled}
        <div class="hide-file-ui">
          <Textfield bind:files={profileImageFiles} label="Profilbild" type="file"></Textfield>
        </div>
        <IconButton on:click={async () => {}} class="material-icons">save</IconButton>
      {:else}
        <IconButton on:click={() => (profileImageFilesEnabled = true)} class="material-icons">edit</IconButton>
      {/if}
    </div>
  </div>
  <div slot="loading">Loading...</div>
</Document>

<style lang="scss">
  .group {
    display: flex;
    align-items: center;
    margin-right: 1em;
    margin-bottom: 1em;
  }

  .m {
    margin: 1em;
  }

  .mr {
    margin-right: 1em;
  }

  .linethrough {
    text-decoration: line-through;
  }

  .hide-file-ui :global(input[type='file']::file-selector-button) {
    display: none;
  }

  .hide-file-ui :global(:not(.mdc-text-field--label-floating) input[type='file']) {
    color: transparent;
  }
</style>
