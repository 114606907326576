import { get, writable } from "svelte/store";
import type { Readable } from "svelte/store";
import {
  connectFirestoreEmulator,
  initializeFirestore,
} from "@firebase/firestore";
import type { Firestore, FirestoreSettings } from "@firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "@firebase/app-check";
import type { AppCheck, AppCheckOptions } from "@firebase/app-check";
import {
  initializeApp,
} from "@firebase/app";
import type { FirebaseApp } from "@firebase/app";
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  connectAuthEmulator,
  initializeAuth,
  onAuthStateChanged,
  useDeviceLanguage,
} from "@firebase/auth";
import type { User, Auth } from "@firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "@firebase/functions";
import type { Functions } from "@firebase/functions";
import { getPerformance } from "@firebase/performance";
import type { FirebasePerformance } from "@firebase/performance";
import { getMessaging } from "@firebase/messaging";
import type { Messaging } from "@firebase/messaging";
import { environment } from "./env/environment";

function initializeEnvironment() {
  if (environment.FIREBASE_APPCHECK_DEBUG_TOKEN) {
    // @ts-ignore
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = environment.FIREBASE_APPCHECK_DEBUG_TOKEN;
    // @ts-ignore
    console.log('self.FIREBASE_APPCHECK_DEBUG_TOKEN=', self.FIREBASE_APPCHECK_DEBUG_TOKEN);
  }

  if (environment.FIREBASE_TARGET !== 'production') {
    console.log('target: ', environment.FIREBASE_TARGET);
  }
}

const firestoreSettings: FirestoreSettings = { ignoreUndefinedProperties: true };
const appCheckOptions: AppCheckOptions = {
  provider: new ReCaptchaV3Provider('6LcGJ2IgAAAAAAGbH0gtturYEw_MGhAJyr12N5qz'),
  isTokenAutoRefreshEnabled: true,
};

let app: FirebaseApp;
let auth: Auth;
let firestore: Firestore;
let functions: Functions;
let performance: FirebasePerformance;
let messaging: Messaging;
let appCheck: AppCheck;

let firebaseAppInitializedWritable = writable(false);
export const firebaseAppInitialized = firebaseAppInitializedWritable as Readable<boolean>;

let afterInitializeCallbacks: (() => void)[] = [];
export function afterInitialize(callback: () => void) {
  if (get(firebaseAppInitialized)) {
    callback();
  } else {
    afterInitializeCallbacks.push(callback);
  }
}

function runAfterInitializeCallbacks() {
  for (const callback of afterInitializeCallbacks) {
    setTimeout(callback, 0);
  }
  afterInitializeCallbacks = [];
}

export function initializeFirebaseApp() {
  console.debug('initialize: initializing firebase app');

  initializeEnvironment();

  app = initializeApp(environment.FIREBASE_CONFIG);
  auth = initializeAuth(app, {
    persistence: browserLocalPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
  });

  console.debug('initialize: initializing firebase services');

  firestore = initializeFirestore(app, firestoreSettings);
  functions = getFunctions(app, 'europe-west3');
  messaging = getMessaging(app);
  performance = getPerformance(app);
  appCheck = initializeAppCheck(app, appCheckOptions);

  if (environment.FIREBASE_TARGET === 'emulators') {
    console.debug('initialize: connecting firebase service emulators');
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
  }

  useDeviceLanguage(auth);
  onAuthStateChanged(auth, handleAuthStateChanged);
  runAfterInitializeCallbacks();
  firebaseAppInitializedWritable.set(true);
}

let unsub: () => void;

function isVerifiedInsiderPieEmployee(newUser: User) {
  return newUser.email.endsWith('@insiderpie.de') || !newUser.emailVerified;
}

export function handleAuthStateChanged(newUser: User | null) {
  if (unsub) {
    unsub();
  }
  if (newUser) {
    console.debug(`auth: logged in uid=${newUser.uid} isAnonymous=${newUser.isAnonymous}`);
    if (!isVerifiedInsiderPieEmployee(newUser)) {
      location.href = "https://app.insiderpie.de";
      return;
    }

    user = newUser;
  } else {
    console.debug('auth: logged out');
    user = null;
  }
}

let user: User | null | undefined = undefined;
