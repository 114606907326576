<!-- 
@component
Content inside this component will be removed from the normal document flow
and instead appended to the end of the body. Use for popup dialogs, toast
notifications, stuff like that. 
 -->
<script>
  function addToBody(node) {
    // the node has been mounted in the DOM
    document.body.appendChild(node);
    node.hidden = false;

    return {
      destroy() {
        // the node has been removed from the DOM
        if (node.parentNode === document.body) {
          document.body.removeChild(node);
        }
      },
    };
  }
</script>

<div use:addToBody hidden>
  <slot />
</div>
