<script lang="ts">
  import type { User } from "@firebase/auth";
  import LayoutGrid, { Cell } from "@smui/layout-grid/styled";
  import LoginUI from "./LoginUI.svelte";
  import ResponsiveSpaceCell from "../util/ResponsiveSpaceCell.svelte";

  export let user: User;
</script>

<LayoutGrid>
  <ResponsiveSpaceCell span={2} />
  <Cell span={8}>
    <LoginUI {user} />
  </Cell>
  <ResponsiveSpaceCell span={2} />
</LayoutGrid>
