/**
 * Sort the array in-place and return a reference to it.
 * @param {any[]} array 
 * @param {'ascending'|'descending'} sortDirection 
 * @param {boolean} isAbsolute 
 */
export function sortArrayBy(array, sortBy, sortDirection = 'ascending', isAbsolute = false) {
  return array.sort((a, b) => {
    // get values to sort by
    let aValue = a[sortBy];
    let bValue = b[sortBy];

    // swap values if sort direction is descending
    if (sortDirection === 'descending') {
      const tmp = aValue;
      aValue = bValue;
      bValue = tmp;
    }

    // compare strings
    if (typeof aValue === 'string') {
      return aValue.localeCompare(bValue);
    }

    // compare numbers
    if (isAbsolute) {
      return Math.abs(aValue) - Math.abs(bValue);
    }
    return aValue - bValue;
  });
}

/**
 * Sort the array in-place and return a reference to it.
 * @param {any[]} array 
 * @param {'ascending'|'descending'} sortDirection 
 * @param {boolean} isAbsolute 
 */
 export function sortArrayByKey(array, key, sortDirection = 'ascending', isAbsolute = false) {
  return array.sort((a, b) => {
    // get values to sort by
    let aValue = key(a);
    let bValue = key(b);

    // swap values if sort direction is descending
    if (sortDirection === 'descending') {
      const tmp = aValue;
      aValue = bValue;
      bValue = tmp;
    }

    // compare strings
    if (typeof aValue === 'string') {
      return aValue.localeCompare(bValue);
    }

    // compare numbers
    if (isAbsolute) {
      return Math.abs(aValue) - Math.abs(bValue);
    }
    return aValue - bValue;
  });
}