<script>
  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Label,
  } from "@smui/data-table/styled";
  import IconButton from '@smui/icon-button/styled';
  import { navigateTo } from "yrv";
  import { sortArrayBy } from "../../util/tables";

  export let investors;

  let investorsList = [];
  let sort = "value";
  let sortDirection = "descending";

  function handleSort() {
    investorsList = sortArrayBy(investorsList, sort, sortDirection);
  }

  $: {
    investorsList = investors;
    handleSort();
  }

  function formatDate(value) {
    if (typeof value === 'object' && 'seconds' in value) {
      return new Date(value.seconds * 1000).toLocaleDateString();
    }
    return '';
  }
  const percentageFormatter = new Intl.NumberFormat('de-DE', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  function formatPercentage(percent) {
    if (percent !== undefined && percent !== null) {
      return percentageFormatter.format(percent);
    }
    return '';
  }

  function formatDateOrYear(value) {
    if (typeof value === 'number') {
      return value.toString(10);
    }
    return formatDate(value);
  }
  
  function formatIsDefined(value) {
    if (value === undefined || value === null) {
      return '❌';
    }
    return '✅';
  }
</script>

<DataTable
  sortable
  bind:sort
  bind:sortDirection
  on:MDCDataTable:sorted={handleSort}
  style="width: 100%; overflow: auto"
  stickyHeader={true}
>
  <Head>
    <Row>
      <Cell columnId={"name"}>
        <Label>Name</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"cik"}>
        <Label>CIK</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"last13FFilingDate"}>
        <Label>13F-Filing</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"birthDate"}>
        <Label>Geburtsdatum</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"country"}>
        <Label>Land</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"investmentStyleName"}>
        <Label>Stil</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"annualreturn5y"}>
        <Label>Return 5y</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"biography"}>
        <Label>Biographie</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"strategy"}>
        <Label>Strategie</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"insider_comment"}>
        <Label>Insider Comment</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell columnId={"quotes"}>
        <Label>Zitate</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#each investorsList as investor}
      <Row on:click={() => navigateTo(`/investors/${investor.id}`)}>
        <Cell>{investor.name}</Cell>
        <Cell>{investor.cik}</Cell>
        <Cell>{investor.last13FFilingDate}</Cell>
        <Cell>{formatDateOrYear(investor.birthDate, investor.birthYear)}</Cell>
        <Cell>{investor.country}</Cell>
        <Cell>{investor.investmentStyleName}</Cell>
        <Cell>{formatPercentage(investor.annualreturn5y)}</Cell>
        <Cell>{formatIsDefined(investor.biography)}</Cell>
        <Cell>{formatIsDefined(investor.strategy)}</Cell>
        <Cell>{formatIsDefined(investor.insider_comment)}</Cell>
        <Cell>{formatIsDefined(investor.quotes)}</Cell>
      </Row>
    {/each}
  </Body>
</DataTable>
