<script>
  import Dialog, { Title, Content, Actions } from "@smui/dialog/styled";
  import Button, { Label } from "@smui/button/styled";
  import { navigateTo } from "yrv";
  import { getAuth, signOut } from "firebase/auth";
  import AppendToBody from './util/AppendToBody.svelte';
  export let user;
  export let open;

  async function onClickSignOut() {
    await signOut(getAuth());
    navigateTo('/');
  }
</script>

{#if user}
<AppendToBody>
  <Dialog
    bind:open
    aria-labelledby="simple-title"
    aria-describedby="simple-content"
  >
    <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
    <Title id="simple-title">
      {#if user.displayName}
      {user.displayName.trimStart()}
      {:else}
      {user.email}
      {/if}
    </Title>
    <Content id="simple-content">{user.email}</Content>
    <Actions>
      <Button on:click={onClickSignOut}>
        <Label>Abmelden</Label>
      </Button>
      <Button>
        <Label>Schließen</Label>
      </Button>
    </Actions>
  </Dialog>
</AppendToBody>
{/if}
