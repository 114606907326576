<script lang="ts">
  import { getFunctions, httpsCallable } from "@firebase/functions";
  import { getApp } from "@firebase/app";
  import LayoutGrid, { Cell } from "@smui/layout-grid/styled";
  import Button, { Label } from "@smui/button/styled";

  async function triggerDebugNotification() {
    const callable = httpsCallable(
      getFunctions(getApp(), 'europe-west3'),
      'sendDebugNotificationOnRequest'
    );
    await callable();
  }
</script>

<LayoutGrid>
  <Cell span={12}>
    <p>
      You can send yourself a notification. 
      The notification will be delivered according to your notification settings:
    </p>
    <ul>
      <li>If you've enabled E-Mail notifications, you'll get an E-Mail.</li>
      <li>You'll get push notifications on all devices where you've allowed them.</li>
    </ul>
    <Button on:click={triggerDebugNotification}>
      <Label>Send debug notifications</Label>
    </Button>
  </Cell>
</LayoutGrid>

