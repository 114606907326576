<script lang="ts">
  import Textfield from "@smui/textfield/styled";
  import Icon from "@smui/textfield/icon/styled";
  import IconButton from "@smui/icon-button/styled";
  import List, {
    Item,
    Graphic,
    Separator,
    Text,
    PrimaryText,
    SecondaryText,
  } from '@smui/list';
  import { router } from "yrv";
  import networkQuality, { NetworkQuality } from "./util/networkQuality";
  import { getCompanyDisplayName, getCompanyLogo } from "./util/format";
  import { search } from "./search";
  import { createEventDispatcher } from "svelte";

  export let user;

  let canGoBack = false;

  router.subscribe((e) => {
    if (e.initial || e.path === "/") {
      canGoBack = false;
    } else {
      canGoBack = true;
    }
  });

  let query = "";
  let input;
  let focused = false;
  let searchResults = null;

  async function doSearch() {
    if (query.trim() === '') {
      searchResults = null;
      return;
    }

    const result = await search(query);
    console.debug('search:', result);

    searchResults = result.hits;
  }

  const dispatch = createEventDispatcher();

  function onResultSelected(result) {
    dispatch('select', result);
    resetSearch();
  }

  function resetSearch() {
    query = '';
    searchResults = null;
  }

  function isCompany(hit) {
    return !!hit.lei;
  }
</script>

<Textfield
  bind:value={query}
  bind:input
  on:focus={() => (focused = true)}
  on:blur={() => (focused = false)}
  on:input={doSearch}
  withLeadingIcon
  withTrailingIcon={!!query}
  label="Search companies by name, LEI, ISIN, CIK, ..."
  class="search-bar"
  disabled={$networkQuality === NetworkQuality.offline}
>
  <Icon class="material-icons" slot="leadingIcon">
    {#if $networkQuality === NetworkQuality.offline}
      wifi_off
    {:else}
      search
    {/if}
  </Icon>
  <svelte:fragment slot="trailingIcon">
    {#if query}
      <IconButton class="material-icons" on:click={resetSearch}>close</IconButton>
    {/if}
  </svelte:fragment>
</Textfield>

{#if searchResults}
  <List aria-label="Suchergebnisse" class="search-results" id="search-results">
      {#if searchResults.length > 0}
        {#each searchResults as hit}
          {@const highlightName = getCompanyDisplayName(hit)}
          {@const logo = getCompanyLogo(hit)}
          {#if isCompany(hit)}
            <Separator />
            <Item>
              <div on:click={() => onResultSelected(hit)} class="search-result">
                <Graphic class="logo-container">
                  {#if logo}
                    <img src={logo} alt={`${hit.name} logo`} class="company-logo" />
                  {/if}
                </Graphic>
                <Text class="text">
                  <PrimaryText>
                    {#if highlightName}
                      {@html highlightName}
                    {:else}
                      {hit.name}
                    {/if}
                  </PrimaryText>
                  <SecondaryText>
                    {#if hit.investmentStyleName}
                      {hit.investmentStyleName}
                    {:else if hit.tickers?.[0]}
                      {hit.tickers[0]}
                    {/if}
                  </SecondaryText>
                </Text>
              </div>
            </Item>
          {/if}
        {/each}
      {:else}
        <Item>
          <Text>
            <PrimaryText>Keine Ergebnisse</PrimaryText>
          </Text>
        </Item>
      {/if}
  </List>
{/if}

<style>
  .logo {
    padding-right: 6px;
    padding-top: 3px;
  }
  .company-logo {
    margin: auto;
    max-width: 48px;
    max-height: 48px;
  }
  :global(.search-bar) {
    width: 100%;
    flex-grow: 1;
  }
  :global(#search-results) {
    width: 100%;
    position: absolute !important;
    z-index: 1000;
    max-height: calc(100vh - 64px);
    padding: 0;
    overflow: scroll;
    border-left: 1px solid #e7e7e7;
  }
  :global(#search-results .mdc-deprecated-list-item__graphic) {
    width: 48px;
    height: 48px;
  }
  :global(#search-results li.mdc-deprecated-list-item) {
    padding: 16px;
    background-color: white;
  }
  :global(#search-results li.mdc-deprecated-list-divider) {
    border-bottom-color: #e0e0e0;;
  }
  :global(#search-results .search-result) {
    display: flex;
    align-items: center;
  }
  @media screen and (min-width: 600px) {
    :global(.search-bar),
    :global(#search-results) {
      width: calc(100% - 192px);
      margin-left: 72px;
      margin-right: 120px;
    }
  }
  :global(#search-results .mdc-top-app-bar__row) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  :global(#search-results .logo-container) {
    width: 64px;
  }
  :global(#search-results a) {
    width: 100%;
    height: 100%;
    display: inline-block;
    text-decoration: none;
    color: black;
  }
</style>
