import { writable, Readable, Writable } from "svelte/store";

let tasks = 0;
const busyInternal: Writable<boolean> = writable(false);

/**
 * True while there are any tasks passed to runTask that aren't yet settled.
 */
export const busy: Readable<boolean> = busyInternal;

function updateBusy() {
  if (tasks > 0) {
    busyInternal.set(true);
  } else {
    busyInternal.set(false);
  }
}

function isPromise(task): task is Promise<any> {
  return 'finally' in task;
}

/**
 * Add a promise to the list of running promises.
 * The busy store will have a value of true until all promises are settled.
 */
export function runTask<T>(name: string, newTask: Promise<T> | (() => Promise<T>)): Promise<T> {
  tasks++;
  updateBusy();

  // If new task is an async function, we call it and use the promise it returns
  if (!isPromise(newTask)) {
    newTask = newTask();
  }

  console.debug(`runTask: starting task: ${name}`);
  const timeout = setTimeout(() => {
    console.warn(`runTask: long-running task: ${name}`);
  }, 4_000);

  newTask.finally(() => {
    console.debug(`runTask: finished task: ${name}`);
    clearTimeout(timeout);
    tasks--;
    updateBusy();
  });
  return newTask;
}
