<script lang="ts">
  import { collection, getDocs, getFirestore, query, where } from "@firebase/firestore";
  import Button from "@smui/button/styled";
  import { parse } from "csv-parse/browser/esm/sync";
  import { convertCurrency } from "../../../src/shared/convert-currency";
  import by from "../../../src/shared/by";
  import type { IExchangeRate } from "../../../src/shared/types/exchange-rate";

  interface ICSVRecord { Date: string; Close: string; Volume: string; }

  let isin;
  let sourceCurrency;
  let targetCurrency;
  let csv;

  let ok = false;
  let error;

  async function getExchangeRatesForDateRange(from: string, to: string) {
    const snapshot = await getDocs(
      query(
        collection(getFirestore(), 'exchange-rates'),
        where('date', '>=', from),
        where('date', '<=', to)
      )
    );
    const rates = snapshot.docs.map(doc => doc.data()) as IExchangeRate[];
    return rates.sort(by('date', 'desc'));
  }

  function generateSQL(records: ICSVRecord[], exchangeRates: IExchangeRate[]) {
    let sql = '';
    for (const record of records) {
      const exchangeRate = exchangeRates.find(x => x.date <= record.Date);
      if (exchangeRate) {
        const date = record.Date;
        const price = convertCurrency(parseFloat(record.Close), sourceCurrency, targetCurrency, exchangeRate);
        const volume = convertCurrency(parseFloat(record.Volume), sourceCurrency, targetCurrency, exchangeRate);
        sql += `insert into stock_prices (isin, date, price, volume, currency) values ('${isin}', '${date}', ${price}, ${volume}, '${targetCurrency}') on conflict (isin, date) do update set isin='${isin}', date='${date}', price=${price}, volume=${volume}, currency='${targetCurrency}';\n`;
      }
    }
    return sql;
  }

  async function transform() {
    ok = false;
    error = null;
    try {
      const records = parse(csv, {columns: true}) as ICSVRecord[];
      if (records.length === 0) {
        return;
      }

      const exchangeRates = await getExchangeRatesForDateRange(records[0].Date, records.at(-1).Date);
      const sql = generateSQL(records, exchangeRates);
      navigator.clipboard.writeText(sql);
      ok = true;
    } catch (e) {
      error = e;
    }
  }
</script>

<input
  type="text"
  bind:value={sourceCurrency}
  placeholder="Source (CSV) currency"
/>
<input
  type="text"
  bind:value={targetCurrency}
  placeholder="Target (SQL) currency"
/>
<input
  type="text"
  bind:value={isin}
  placeholder="ISIN"
/>

<Button
  on:click={transform}
>
  transform
</Button>

<div>
  {#if ok}
    Ok!
  {/if}
  {#if error}
    {error}
  {/if}
</div>

<textarea bind:value={csv} style="min-width: 85%"></textarea>
