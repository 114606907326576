<script>
  import TopAppBar, { Row, Section, Title } from "@smui/top-app-bar/styled";
  import IconButton from "@smui/icon-button/styled";
  import { Link, router } from "yrv";
  import AccountOptionsDialog from "./AccountOptionsDialog.svelte";

  export let user;

  let userDialogOpen = false;
  let canGoBack = false;
  
  router.subscribe(e => {
    if (e.initial || e.path === '/') {
      canGoBack = false;
    } else {
      canGoBack = true;
    }
  });
</script>

<TopAppBar
  variant="static"
  prominent={false}
  dense={false}
  color="primary"
>
  <Row>
    <Section>
      {#if canGoBack}
      <Link go="back">
        <IconButton class="material-icons" aria-label="Back">arrow_back</IconButton>
      </Link>
      {:else}
      <Link href="/" class="invis-link">
        <img
          src="/insiderpie.jpg"
          alt="InsiderPie logo"
          width="42em"
          height="42em"
        />
      </Link>
      {/if}
      <Title>
        internal.InsiderPie
      </Title>
    </Section>
    <Section align="end" toolbar>
      <Link href="/" class="invis-link">
        <IconButton class="material-icons" aria-label="Homepage">home</IconButton>
      </Link>
      {#if user}
      <IconButton
        class="material-icons"
        aria-label="Dein Account"
        on:click={() => (userDialogOpen = true)}>account_circle</IconButton
      >
      {/if}
    </Section>
  </Row>
</TopAppBar>

<AccountOptionsDialog bind:open={userDialogOpen} {user} />

