<script>
  import { getContext } from "svelte";  
  import networkQuality, { NetworkQuality } from "../../util/networkQuality";
  
  const firebase = getContext('firebase').getFirebase();
  const storage = firebase.storage();
  
  export let investor;
  export let investorId;
  export let prefix = 'profile';
  export let chip = false;
  
  /**
   * Returns an array of [AVIF, WebP, PNG] image URLs
   */
  async function getImageUrls(id, prefix) {
    let size;
    if ($networkQuality === NetworkQuality.high) {
      size = 'large';
    } else {
      size = 'medium';
    }
    return await Promise.all([
      storage.ref(`investors/${id}/${prefix}_${size}.avif`).getDownloadURL(),
      storage.ref(`investors/${id}/${prefix}_${size}.webp`).getDownloadURL(),
      storage.ref(`investors/${id}/${prefix}_${size}.png`).getDownloadURL()
    ]);
  }
  function getImgStyle(investor, imageUrls = null) {
    let style = '';
    const aspectRatio = investor[`${prefix}ImageAspectRatio`];
    if (aspectRatio) {
      style += `padding-top: ${aspectRatio}%; `;
    }
    if (imageUrls) {
      const [avifUrl, webpUrl, pngUrl] = imageUrls;
      style += `
        background: #e0e0e0;
        background-image: url("${pngUrl}");
        background-image: -webkit-image-set(
          url("${avifUrl}") type("image/avif"),
          url("${webpUrl}") type("image/webp"));
        background-image: image-set(
          url("${avifUrl}") type("image/avif"),
          url("${webpUrl}") type("image/webp"));
        background-size: cover;`;
    } else {
      style += 'background-color: #e0e0e0; '
    }
    if (chip) {
      style += 'border-radius: 16px; '
    }
    return style;
  }
</script>

{#await getImageUrls(investorId, prefix)}
<div style={getImgStyle(investor)} />
{:then imageUrl}
<div style={getImgStyle(investor, imageUrl)} />
{:catch error}
<div style={getImgStyle(investor)} />
{/await}

<style lang="scss">
  div {
    max-width: 100%;
  }
</style>
