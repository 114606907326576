<script>
  import { navigateTo } from 'yrv';
  import Textfield from '@smui/textfield/styled';
  import HelperText from '@smui/textfield/helper-text/styled';
  import Icon from '@smui/textfield/icon/styled';
  import IconButton from '@smui/icon-button/styled';
  import List, { Item, Separator, Text, Meta } from '@smui/list/styled';
  import Collection from '../../util/Collection.svelte';
  import { getFirestore, collection, addDoc, deleteDoc } from "firebase/firestore";

  let newTitle = '';
  let showNew = false;

  async function deleteDraft(ev, doc) {
    ev.cancelBubble = true;
    if (confirm(`Der Entwurf wird gelöscht: ${doc.get('title')}. Das kann nicht rückgängig gemacht werden!`)) {
      await deleteDoc(doc.ref);
    }
  }

  async function addDraft(newTitle) {
    await addDoc(
      collection(getFirestore(), 'newsletter-drafts'),
      { title: newTitle }
    )
  }
</script>

<Collection path={'newsletter-drafts'} let:data={drafts} let:snapshot>
  <div class="topbar m">
    <h2 class="mbr">Newsletter Entwürfe</h2>
    <IconButton class="material-icons" style="margin-right: 1em; margin-top: 0.5em; margin-bottom: 1em" on:click={() => showNew = true}>post_add</IconButton>
    {#if showNew}
    <div>
      <Textfield bind:value={newTitle} label="Name" variant="outlined" style="min-width: 300px;" withTrailingIcon={!!newTitle}>
        <svelte:fragment slot="trailingIcon">
          {#if newTitle}
            <Icon class="material-icons" role="button" on:click={addDraft(newTitle)}>add</Icon>
          {/if}
        </svelte:fragment>
        <HelperText persistent slot="helper">Gib einen Namen ein, um einen neuen Entwurf zu erstellen</HelperText>
      </Textfield>
    </div>
    {/if}
  </div>
  <div class="m">
    <List>
      {#each snapshot.docs as doc}
      <Separator />
      <Item on:click={() => navigateTo(`/draft-newsletter/${doc.id}`)}>
        <!-- <div style="display: flex; justify-content: space-between; align-items: center; width: 100%"> -->
          <Text>{doc.get('title')}</Text>
          <Meta on:click={async (ev) => await deleteDraft(ev, doc)} class="material-icons">
            delete_forever
          </Meta>
        <!-- </div> -->
      </Item>
      {/each}
      <Separator />
    </List>
  </div>
</Collection>

<style lang="scss">
  .topbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .mbr {
    margin-top: 0.4em;
    margin-right: 1em;
    margin-bottom: 1em;
  }

  .m {
    margin: 1em;
  }
</style>
